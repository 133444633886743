import { ascending } from "d3";
import Map from "./map";
import Timeline from "./timeline";

const Filters = {
    addFilters: () => {
        d3.selectAll(".dates *").remove();
        d3.selectAll(".tags *").remove();
        d3.selectAll(".places *").remove();
        d3.selectAll(".people *").remove();
        d3.selectAll(".items *").remove();


        d3.select(".dates")
            .append("div")
            .selectAll(".date")
            .data(dates)
            .enter()
            .append("label")
            .html((d) => {
                let checked = datesSelected.includes(d) ? "checked" : "";
                return `<input ${checked} class="date" type="radio" name="date_input" value="${d}"><span>${d}</span>`;
            });

        d3.select(".tags")
            .append("div")
            .selectAll(".tag")
            .data(tags)
            .enter()
            .append("label")
            .html((d) => {
                let checked = tagsSelected.includes(d) ? "checked" : "";
                return `<input ${checked} class="tag" type="radio" name="tag_input" value="${d}"><span>${d}</span>`;
            });

        d3.select(".places")
            .append("div")
            .selectAll(".place")
            .data(places)
            .enter()
            .append("label")
            .html((d) => {
                let checked = placesSelected.includes(d) ? "checked" : "";
                return `<input ${checked} class="place" type="radio" name="place_input" value="${d}"><span>${d}</span>`;
            });

        d3.select(".people")
            .append("div")
            .selectAll(".person")
            .data(people)
            .enter()
            .append("label")
            .html((d) => {
                let checked = peopleSelected.includes(d) ? "checked" : "";
                return `<input ${checked} class="person" type="radio" name="person_input" value="${d}"><span>${d}</span>`;
            });

        Timeline.init();
        // d3.select(".items")
        //     .selectAll(".item")
        //     .data(filtered)
        //     .enter()
        //     .append("div")
        //     .append("span")
        //     .text((d) => d["Letter topic"]);
    },
    updateFilters: () => {
        d3.selectAll(".date").on("click", function () {
            if (datesSelected.includes(this.value)) {
                d3.select(this).property('checked', false);
            }
            datesSelected = [];
            d3.selectAll(".date:checked").each(function () {
                datesSelected.push(this.value);
            });
            Map.draw(dataset);
            Timeline.draw()
        });

        d3.selectAll(".tag").on("click", function () {
            if (tagsSelected.includes(this.value)) {
                d3.select(this).property('checked', false);
            }
            tagsSelected = [];
            d3.selectAll(".tag:checked").each(function () {
                tagsSelected.push(this.value);
            });
            Map.draw(dataset);
            Timeline.draw()
        });

        d3.selectAll(".place").on("click", function () {
            if (placesSelected.includes(this.value)) {
                d3.select(this).property('checked', false);
            }
            placesSelected = [];
            d3.selectAll(".place:checked").each(function () {
                placesSelected.push(this.value);
            });
            Map.draw(dataset);
            Timeline.draw()
        });

        d3.selectAll(".person").on("click", function () {
            if (peopleSelected.includes(this.value)) {
                d3.select(this).property('checked', false);
            }
            peopleSelected = [];
            d3.selectAll(".person:checked").each(function () {
                peopleSelected.push(this.value);
            });
            Map.draw(dataset);
            Timeline.draw()
        });

        d3.selectAll(".reset").on("click", function () {
            d3.selectAll("input[type=radio]").property("checked", false);
            datesSelected = [];
            tagsSelected = [];
            placesSelected = [];
            peopleSelected = [];
            Map.draw(dataset);
            Timeline.draw()
        });

    },
    filter: (data) => {
        if (datesSelected.length > 0) {
            data = data.filter((d) => {
                return (
                    datesSelected.includes(d["Letter date"].split("-").slice(-1)[0]) 
                    || datesSelected.includes(d["Newsletter date"].split("-").slice(-1)[0])
                );
            });
        }
        if (placesSelected.length > 0) {
            data = data.filter((d) => {
                return (
                    placesSelected.includes(d["Sender location"]) ||
                    placesSelected.includes(d["Receiver location"])
                );
            });
        }
        if (peopleSelected.length > 0) {
            data = data.filter((d) => {
                return (
                    peopleSelected.includes(d["Sender"]) 
                    || peopleSelected.includes(d["Receiver"]
                    )
                );
            });
        }
        if (tagsSelected.length > 0) {
            data = data.filter((d) => {
                return (
                    tagsSelected.includes(d["Letter tags 1"]) ||
                    tagsSelected.includes(d["Letter tags 2"])
                );
            });
        }
        return data;
    },
    dates: (data) => {
        return Array.from(
            new Set(
                data.flatMap((l) => [
                    // l["Letter date"].split("-").slice(-1)[0],
                    l["Newsletter date"].split("-").slice(-1)[0]
                ])
            )
        ).filter(item => item).sort();
    },
    places: (data) => {
        return Array.from(new Set(data.flatMap(l => [l["Sender location"], l["Receiver location"]])))
            .filter(item => item)
            .sort();
    },
    people: (data) => {
        return Array.from(new Set(data.flatMap(l => [
            l["Sender"],
            // l["Receiver"]
        ]))).sort();
    },
    tags: (data) => {
        return Array.from(new Set(data.flatMap(l => [l["Letter tags 1"], l["Letter tags 2"]]))).filter(item => item).sort();
    },
};

export default Filters;