import "../style.css";

import Website from "./_modules/website";
import Home from "./_modules/home";

window.addEventListener('load', function () {

  // const scrollContainer = document.querySelectorAll(".filters > div");

  // scrollContainer.forEach(box => {
  //   box.addEventListener("wheel", (evt) => {
  //     evt.preventDefault();
  //     box.scrollLeft += evt.deltaY;
  //   });
  // });

  Website.signature();
  Home.init();
})