import Map from "./map";
import Timeline from "./timeline";
globalThis.d3 = require('d3');

const Home = {
  init: () => {

    Map.init();
    Timeline.init();

    // document.getElementsByName('selector').forEach(function (e) {
    //   e.addEventListener("click", function () {
    //     if (e.value == "render_timeline") {
    //       d3.select(".inner-viz").remove()
    //       Timeline.init();
    //     }
    //     if (e.value == "render_map") {
    //       d3.select(".inner-viz").remove()
    //       Map.init();
    //     }
    //   });
    // });

    // document.querySelectorAll(".space-js").forEach(function (t, e, n) {
    //   let a = 1;
    //   var i = t.querySelectorAll("span").length
    //     , r = Math.floor(i / 2)
    //     , o = a / r
    //     , s = (100 - a) / (i - r);
    //   t.querySelectorAll("div:nth-of-type(-n+" + r + ")").forEach(function (t) {
    //     t.style.width = o + "%"
    //   }),
    //     t.querySelectorAll("div:nth-of-type(n+" + (r + 1) + ")").forEach(function (t) {
    //       t.style.width = s + "%"
    //     })
    // })


  }
};


export default Home;
