import * as topojson from "topojson-client";
import Filters from "./filters";
import Timeline from "./timeline";

globalThis.datesSelected = [];
globalThis.placesSelected = [];
globalThis.peopleSelected = [];
globalThis.tagsSelected = [];
globalThis.land = []
globalThis.filtered = [];
globalThis.dataset = [];
globalThis.center = [];
const sensitivity = 70;

const Map = {
    loadData: () => {
        return d3.csv("https://docs.google.com/spreadsheets/d/1fzG_wDLDJ3ThudFjcDDhe_HoF8CmDH47sxhNFgYwsec/gviz/tq?tqx=out:csv");
    },
    variables: () => {
        globalThis.width = 928
        globalThis.height = 440
        globalThis.scale = 173

        globalThis.sphere = { type: "Sphere" };

        globalThis.projection = d3.geoTransverseMercator()
            .scale(scale)
            .rotate([0, 0])
            .clipAngle(82.7);

        globalThis.geoPath = d3.geoPath()
            .projection(projection)

        globalThis.path = d3.geoPath(projection)

        globalThis.svg = d3.select('.viz-container')
            .append('div')
            .attr("class", "inner-viz")
            .attr("id", "map-js")
            .append('svg')
            .attr("width", 960)
            .attr("height", 500)
            .call(responsivefy)

        globalThis.graticule = d3.geoGraticule()
            .extent([[-180, -80], [180, 80.2]])
            .step([20, 20]);

        globalThis.defs = svg.append("defs");

        defs.append("path")
            .datum(sphere)
            .attr("class", "sphere")
            .attr("id", "outline")
            .attr("d", geoPath)

        defs.append('clipPath')
            .attr("id", "clip")
            .html(`<use xlink:href="${new URL(" #outline", location)}" />`)

        globalThis.clip = svg.append('g')
            .attr("clip-path", `url(${new URL("#clip", location)})`)

        clip.append("use")
            .attr("xlink:href", `${new URL("#outline", location)}`)

        globalThis.globe = clip.append('g').attr('class', 'map');

        svg.append('use')
            .append("a")
            .attr("xlink:href", "#sphere")

        svg.call(d3.drag().on('drag', (d) => {
            const rotate = projection.rotate()
            const k = sensitivity / projection.scale()
            projection.rotate([
                rotate[0] + d.dx * k,
                rotate[1] - d.dy * k
            ])
            globalThis.geoPath = d3.geoPath().projection(projection)
            svg.selectAll("path").attr("d", globalThis.geoPath)
            Map.refresh();
        }))

        let zoom = d3.zoom()
            .on('zoom', handleZoom)
            .scaleExtent([1, 10]);


        function handleZoom(e) {
            d3.select('svg g')
                .attr('transform', e.transform);
        }

        // initZoom()
        function initZoom() {
            d3.select('svg')
                .call(zoom);
        }
    },
    refresh: () => {
        svg.selectAll(".sphere").attr("d", geoPath);
        svg.selectAll(".country").attr("d", geoPath);
        svg.selectAll(".graticule").attr("d", geoPath);

        Map.position_labels();
        svg.selectAll(".circle")
            .attr("cx", (d) =>
                d.value[0].lat ? projection([fixNumber(d.value[0].lon), fixNumber(d.value[0].lat)])[0] : "")
            .attr("cy", (d) =>
                d.value[0].lon ? projection([fixNumber(d.value[0].lon), fixNumber(d.value[0].lat)])[1] : "")

        svg.selectAll(".line")
            .attr(
                "x1",
                (d) => projection([fixNumber(d.value[0].receiver_lon), fixNumber(d.value[0].receiver_lat)])[0])
            .attr(
                "y1",
                (d) => projection([fixNumber(d.value[0].receiver_lon), fixNumber(d.value[0].receiver_lat)])[1])
            .attr(
                "x2",
                (d) => projection([fixNumber(d.value[0].sender_lon), fixNumber(d.value[0].sender_lat)])[0])
            .attr(
                "y2",
                (d) => projection([fixNumber(d.value[0].sender_lon), fixNumber(d.value[0].sender_lat)])[1]);
    },
    position_labels: () => {
        let centerPos = projection.invert([width / 2, height / 2]);

        svg.selectAll('.circle')
            .style("display", (d) => {
                var j = d3.geoDistance([fixNumber(d.value[0].lon), fixNumber(d.value[0].lat)], centerPos);
                return (j > 1.57) ? 'none' : 'inline';
            })
    },
    addLocations: (data) => {
        d3.selectAll(".circle").remove();
        d3.selectAll(".line").remove();

        const filteredGroup = Array.from(
            d3.group(data, (d) => d["Sender location"]),
            ([coord, value]) => ({ coord, value })
        )

        let receiver_group;
        receiver_group = filtered.map((d) => {
            return {
                lat: d.receiver_lat,
                lon: d.receiver_lon,
                city: d["Receiver location"]
            };
        });

        const receiver = Array.from(
            d3.group(receiver_group, (d) => d.city),
            ([city, value]) => ({ city, value })
        );

        let sender_group;
        sender_group = filtered.map((d) => {
            return {
                lat: d.sender_lat,
                lon: d.sender_lon,
                city: d["Sender location"]
            };
        });

        const sender = Array.from(
            d3.group(sender_group, (d) => d.city),
            ([city, value]) => ({ city, value })
        );

        const max = d3.max(filteredGroup, d => d.value.length)
        const min = d3.min(filteredGroup, d => d.value.length)
        const scale = d3.scaleLinear().domain([min, max]).range([1, 10]);

        d3.select(".lines")
            .selectAll(".line")
            .data(filteredGroup)
            .enter()
            .append("line")
            .attr("class", "line")
            .attr("stroke", "black")
            .attr("stroke-width", (d) => scale(d.value.length))
            .attr("stroke-width", 5)
            .attr(
                "x1",
                (d) => projection([fixNumber(d.value[0].receiver_lon), fixNumber(d.value[0].receiver_lat)])[0]
            )
            .attr(
                "y1",
                (d) => projection([fixNumber(d.value[0].receiver_lon), fixNumber(d.value[0].receiver_lat)])[1]
            )
            .attr(
                "x2",
                (d) => projection([fixNumber(d.value[0].sender_lon), fixNumber(d.value[0].sender_lat)])[0]
            )
            .attr(
                "y2",
                (d) => projection([fixNumber(d.value[0].sender_lon), fixNumber(d.value[0].sender_lat)])[1]
            );

        d3.select(".dots")
            .selectAll(".circle")
            .data([...receiver, ...sender])
            .enter()
            .append("circle")
            .attr("class", "circle")
            .attr("fill", "var(--dark)")
            .attr("cx", (d) =>
                d.value[0].lat ? projection([fixNumber(d.value[0].lon), fixNumber(d.value[0].lat)])[0] : ""
            )
            .attr("cy", (d) =>
                d.value[0].lon ? projection([fixNumber(d.value[0].lon), fixNumber(d.value[0].lat)])[1] : ""
            )
            .on("click", function (e, i) {
                d3.selectAll("input[type=radio]").property("checked", false);
                placesSelected = [i.value[0].city];
                let datum = dataset.filter(d => d.sender_lat && d.sender_lat == i.value[0].lat)
                Map.draw(datum);
                // Timeline.draw(datum)
                d3.select(".place").attr("value", i.value[0].city).attr("checked", true)
            });
    },
    updateCenter: (center) => {
        d3.selectAll("path")
            .transition()
            .attrTween("d", function (d) {
                globalThis.geoPath.projection(projection);
                var r = d3.interpolate(projection.rotate(), [-fixNumber(center[1]), -fixNumber(center[0])]);
                return function (t) {
                    projection.rotate(r(Math.pow(t, 0.33)))
                    Map.refresh()
                    return globalThis.geoPath(d);
                }
            })
            .duration(1000);
    },
    init: (data) => {
        Map.variables();

        d3.json('https://raw.githubusercontent.com/d3/d3.github.com/master/world-110m.v1.json').then(function (world) {
            const countries = topojson.feature(world, world.objects.land).features;

            globe.selectAll('.country')
                .data(countries)
                .enter()
                .append('path')
                .attr('class', 'country')
                .attr('d', geoPath)

            globe.append('path')
                .datum(graticule)
                .attr('class', 'graticule')
                .attr('d', geoPath)

            globe.append("g").attr("class", "lines")
            globe.append("g").attr("class", "dots")

        });

        Map.loadData().then(async function (data) {
            dataset = data;
            Map.draw(data);
        });
    },
    draw: (data) => {
        filtered = Filters.filter(data).filter(d => d.sender_lat)
        globalThis.dates = Filters.dates(filtered);
        globalThis.places = Filters.places(filtered);
        globalThis.people = Filters.people(filtered);
        globalThis.tags = Filters.tags(filtered);
        Filters.addFilters();
        Map.addLocations(filtered);
        Filters.updateFilters();
        Map.updateCenter([filtered[0].sender_lat, filtered[0].sender_lon]);
    }
};

export default Map;

function responsivefy(svg) {
    var container = d3.select(svg.node().parentNode),
        width = parseInt(svg.style("width")),
        height = parseInt(svg.style("height")),
        aspect = width / height;

    svg.attr("viewBox", "0 0 " + width + " " + height)
        .attr("perserveAspectRatio", "xMinYMid")
        .call(resize)

    d3.select(window).on("resize." + container.attr("id"), resize);

    function resize() {
        var targetWidth = parseInt(container.style("width"));
        svg.attr("width", targetWidth);
        svg.attr("height", Math.round(targetWidth / aspect));
    }
}

function fixNumber(num) {
    if (num) {
        return Number(num.replace(",", "."))
    }
    else {
        return NaN
    }
}
